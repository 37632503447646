/* You can add global styles to this file, and also import other style files */
@import 'material-icons/iconfont/material-icons.css';

.modal-dialog {
    width: 100% !important;
    margin: auto;
    .modal-content {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
    .confirm {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      min-height: 150px;
      .content {
        padding: 20px;
        border-radius: 10px;
        background-color: #fff !important;
        font-weight: 700;
      }
      .buttons {
        padding: 10px 15px;
        button {
          margin-right: 10px;
          justify-content: space-between;
        }
      }
    }
  }

@import "~bootstrap-icons/font/bootstrap-icons.css";
